<template>
  <div class="bet-list--inner">
    <check-data-list
      v-if="isDataLoading || isEmpty"
      :isLoading="isDataLoading"
      :isEmpty="isEmpty"
    />

    <div
      v-else
      class="betslip__items"
    >
      <betslip-card
        v-for="(slip, index) in betHistory"
        :key="index"
        :logs="slip"
        :game="$route.params.game"
        :showButtons="true"
        @update="search()"
      >
        <template v-if="slip.bet_info && slip.bet_info.length > 0">
          <bets-card
            v-for="(bets, idx) in slip.bet_info"
            :key="idx"
            :logs="bets"
            :slip="slip"
          />
        </template>
      </betslip-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= components
import BetslipCard from '@/components/common/betslip/BetslipCard'
import BetsCard from '@/components/common/betslip/BetsCard'
import CheckDataList from '@/components/base/CheckDataList'

//= translation
import { translations } from '@/assets/js/translations/Betslip'

import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

export default {
  name: 'BetListTransaction',

  components: {
    BetslipCard,
    BetsCard,
    CheckDataList
  },

  data () {
    return {
      translations,
      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false
    }
  },

  computed: {
    ...mapState('bet-history', ['betHistory'])
  },

  created () {
    this.search()
  },

  methods: {
    ...mapActions('bet-history', { getBetHistory: 'get' }),

    async search () {
      this.isDataLoading = true
      this.showDataLoading = true
      this.isEmpty = true

      const bgId = _get(this.$route.query, 'bgId')
      const uId = _get(this.$route.query, 'uId')
      const gameType = _get(this.$route.query, 'type')
      const status = _get(this.$route.params, 'status')
      const type = _get(this.$route.params, 'game')

      const params = { page: 1, rows: 50, user_id: uId, memo: { bgId }, gameType }

      await this.getBetHistory({ type, status, params })

      this.isDataLoading = false
      this.showDataLoading = false
      this.isEmpty = _isEmpty(this.betHistory)
    }
  }
}
</script>
